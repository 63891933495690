<template>
  <div class="contact container">
    <div class="col-12 col-md-8 col-lg-6">
      <div class="row align-items-center">
        <div class="col-12">
          <h1 class="mb-3 text-title">{{ $t('page.contact') }}</h1>
          <p>{{ $t('contactDescription') }}</p>
        </div>
        <div class="col-12">
          <h2 class="mb-3 text-caption">{{ $t('sendMeAMessage') }}</h2>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="floatingInput" placeholder="nome@email.com">
            <label for="floatingInput">{{ $t('labelsInput.email') }}</label>
          </div>
          <div class="form-floating">
            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="min-height: 128px;"></textarea>
            <label for="floatingTextarea2">{{ $t('labelsInput.message') }}</label>
          </div>
        </div>
        <div class="col-12 row justify-content-center">
          <div class="col-auto">
            <button type="button" class="btn btn-action my-5"> {{ $t('textButtons.send') }} <i class="las la-paper-plane"></i> </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeBackground } from "@/assets/js/background.js";
export default {
  name: 'Contact',
  created() {
    changeBackground("contact")
  }
}
</script>